import { Dispatch } from 'redux';
import * as queryString from 'query-string-for-all';

import { IState } from '../reducers';
import { apiUrl } from '../configuration/url';
import { EPermissionObject, EPermissionType, IAddAdmin, IPermission } from '../entities/IPermission';

export const getSinglePermission = (permissionId: string): Promise<IPermission> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/permission/${permissionId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((res: any) => res.json()).then((response: IPermission) => {
        return response;
    });
}) as any;

export const deleteSinglePermission = (permissionId: string): Promise<any> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/permission/${permissionId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then(() => {
        return true;
    });
}) as any;

export const createSinglePermission = (body: IPermission): Promise<IPermission> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/permission`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify(body)
    }).then((res: any) => res.json()).then((response: IPermission) => {
        return response;
    });
}) as any;

export const addAdmin = (body: IAddAdmin): Promise<IPermission> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/permission/addAdmin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify(body)
    }).then((res: any) => res.json()).then((response: IPermission) => {
        return response;
    });
}) as any;

export const searchAllPermission = (payload: {objectId?: string, objectType?: EPermissionObject, permissionType?: EPermissionType, userId?: string}, size?: number): Promise<IPermission[]> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    const queryParams = queryString.stringify(payload);
    return fetch(`${apiUrl}/api/permission?size=${size || 9999}&${queryParams}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((res: any) => res.json()).then(res => res.content).then((response: IPermission[]) => {
        return response;
    });
}) as any;

export const searchAllAdmins = (payload: {objectId?: string, objectType?: EPermissionObject, permissionType?: EPermissionType, userId?: string}, size?: number): Promise<IPermission[]> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    const queryParams = queryString.stringify(payload);
    return fetch(`${apiUrl}/api/permission/getAdmins?size=${size || 9999}&${queryParams}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((res: any) => res.json()).then(res => res.content).then((response: IPermission[]) => {
        return response;
    });
}) as any;
